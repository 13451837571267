import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

export const cleanBlog = createAction('CLEAN_BLOG');

export const getBlogList = createAction(
	'GET_BLOG',
	(type, id, page = 1, per_page = 9, tags = [], regions = []) => async (dispatch, getState) => {
		const {
			common: { category },
		} = getState();

		let body = {};
		if (type === 'region' || type === 'tag') {
			const targetId = category.find(item => item.name === '今天想去哪')
				? category.find(item => item.name === '今天想去哪').id
				: 1;

			body = {
				category_id: targetId,
				page,
				per_page,
				tags,
				regions,
			};
		} else if (type === 'child') {
			body = {
				subcategory_id: id,
				page,
				per_page,
				tags,
				regions,
			};
		} else {
			body = {
				category_id: id,
				page,
				per_page,
				tags,
				regions,
			};
		}

		const { status, message, data } = await wrapFetch('post', {
			method: 'POST',
			body: JSON.stringify(body),
		});

		if (status !== 200) {
			throw new Error(message);
		}

		return data;
	},
);

export const getBlogListLan = createAction(
	'GET_BLOG',
	(lan, id, page = 1, per_page = 9) => async () => {
		const handleLan = lan === 'jp' ? 'ja_JP' : 'en_US';
		let body = {};
		if (lan === 'jp') {
			body = {
				lang: handleLan,
				page,
				per_page,
			};
		} else {
			body = {
				category_id: id,
				lang: handleLan,
				page,
				per_page,
			};
		}

		const { status, message, data } = await wrapFetch('post/language', {
			method: 'POST',
			body: JSON.stringify(body),
		});

		if (status !== 200) {
			throw new Error(message);
		}
		return data;
	},
);

export const getBlogDetail = createAction('GET_BLOG_DETAIL', id => async () => {
	const { data, status, message } = await wrapFetch(`post/${id}`, {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	if (!data.post) {
		throw new Error('isEmptyData');
	}

	return data;
});

export const previewBlogDetail = createAction('GET_BLOG_DETAIL', (id, token) => async () => {
	const params = {
		method: 'GET',
		headers: {
			authorization: `Bearer ${token}`,
		},
	};

	const { status, message, data } = await wrapFetch(`post/preview/${id}`, params);

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const getRegion = createAction('GET_REGION', () => async () => {
	const { data, status, message } = await wrapFetch('postRegion', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return { postRegions: data.postRegions };
});

export const getTag = createAction('GET_TAG', (id, query) => async () => {
	const params = () => {
		switch (true) {
			case query === 'child':
				return {
					subcategory_id: id,
				};
			case query === 'region':
				return {
					category_id: 1,
				};
			case query === 'tag':
				return null;
			default:
				return {
					category_id: id,
				};
		}
	};
	const { data, status, message } = await wrapFetch(
		'postTag',
		{
			method: 'GET',
		},
		params(),
	);

	if (status !== 200) {
		throw new Error(message);
	}

	return { tag: data.PostTags };
});

export const initializeBlog = createAction('INITIALIZE_BLOG', (id, query) => async dispatch => {
	await dispatch(cleanBlog());
	if (query === 'region') {
		await dispatch(getBlogList(query, 1, 1, 9, [], [id]));
	} else if (query === 'tag') {
		await dispatch(getBlogList(query, 1, 1, 9, [id], []));
	} else {
		await dispatch(getBlogList(query, id));
	}
	await dispatch(getRegion());
	await dispatch(getTag(id, query));
});

export const initializeBlogLan = createAction('INITIALIZE_BLOG', (lan, id) => async dispatch => {
	await dispatch(cleanBlog());
	await dispatch(getBlogListLan(lan, id));
});

export const initializeBlogDetail = createAction(
	'INITIALIZE_BLOG_DETAIL',
	(id, token) => async dispatch => {
		if (token) {
			await dispatch(previewBlogDetail(id, token));
		} else {
			await dispatch(getBlogDetail(id));
		}
	},
);

const reducer = {
	blog: handleActions(
		{
			GET_BLOG_PENDING: state => ({
				...state,

				loading: true,
			}),

			GET_BLOG_FULFILLED: (state, action) => ({
				...state,

				post: action.payload,
				loading: false,
			}),

			GET_REGION_FULFILLED: (state, action) => ({
				...state,

				region: action.payload,
			}),

			GET_TAG_FULFILLED: (state, action) => ({
				...state,

				tag: action.payload,
			}),

			GET_BLOG_DETAIL_PENDING: state => ({
				...state,

				loading: true,
			}),

			GET_BLOG_DETAIL_FULFILLED: (state, action) => ({
				...state,

				postDetail: action.payload,
				loading: false,
			}),

			PREVIEW_BLOG_DETAIL_PENDING: state => ({
				...state,

				loading: true,
			}),

			PREVIEW_BLOG_DETAIL_FULFILLED: (state, action) => ({
				...state,

				postDetail: action.payload,
				loading: false,
			}),

			CLEAN_BLOG: state => ({
				...state,

				post: {},
				postDetail: {},
				region: {},
			}),
		},
		{
			loading: false,
			error: false,
			post: {},
			postDetail: {},
			region: {},
			tag: {},
		},
	),
};

const selectBlog = state => ({
	...state.blog,
});

export const useBlog = () => useRedux(selectBlog, { getBlogList, getBlogListLan });

export default { reducer };
