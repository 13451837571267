import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { useRedux } from 'util/hook/redux';
import { getLangfromURL } from 'util/helper';

export const changeLanguage = createAction('CHANGE_LANGUAGE', lan => {
	document.body.setAttribute('lan', lan);
	return { lan };
});

const reducer = {
	i18n: handleActions(
		{
			CHANGE_LANGUAGE: (state, action) => ({
				...state,
				lan: action.payload.lan,
			}),
		},
		{
			lan: getLangfromURL(),
		},
	),
};

const selectLanguage = createSelector(
	state => state.i18n.lan,
	lan => ({ lan }),
);

export const useLanguage = () => useRedux(selectLanguage);

export default { reducer };
