/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Lottie from 'react-lottie';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useCommon } from 'models/common';
import { useRouting } from 'models/routing';
import { useScroll } from 'util/hook/useScroll';
import { useMedia } from 'util/hook/useMedia';
import AnimatedLogoJson from 'images/icon/animated-logo.json';
import Logo from 'images/icon/logo.png';
import LogoQuote from 'images/icon/logo-quote.png';
import MenuIcon from 'images/icon/menu.inline.svg';
import UserIcon from 'images/icon/user-black.inline.svg';
import SideMenu from 'components/organisms/SideMenu';
import Navigation from 'components/molecules/Navigation';
import SearchButton from 'components/molecules/SearchButton';
import Language from 'components/molecules/Language';
import LoginBox from 'components/molecules/LoginBox';
import MemberBox from 'components/molecules/MemberBox';
import Link from 'components/atoms/Link';
import styles from './index.css';

const Header = ({ className }) => {
	const [
		{ activePage, subcategory, region, magazine, isMember, showLoginBox },
		{ fetchMagazine, toggleLoginBox, checkMemberToken, logIn, logOut },
	] = useCommon();
	const media = useMedia();
	const [{ pathname }] = useRouting();
	const lan = pathname.split('/')[1];

	const [transparentMenuIcon, setTransparentMenuIcon] = useState(false);
	const [fixedHeader, setFixedHeader] = useState(false);
	const [openSideMenu, setOpenSideMenu] = useState(false);
	const [scrollDown, setScrollDown] = useState(false);
	const [scrollDownMobile, setScrollDownMobile] = useState(false);
	const scrollY = useScroll();

	// scroll function
	useEffect(() => {
		if (scrollY <= 10) {
			setFixedHeader(false);
			setScrollDown(false);
			setScrollDownMobile(false);
		} else if (scrollY > 10 && scrollY <= 104) {
			setFixedHeader(false);
			setScrollDown(false);
			setScrollDownMobile(true);
		} else if (scrollY > 104 && scrollY <= 134) {
			setFixedHeader(false);
			setScrollDown(true);
			setScrollDownMobile(true);
		} else if (scrollY > 134 && scrollY < 154) {
			setFixedHeader(false);
			setScrollDown(true);
			setScrollDownMobile(true);
		} else if (scrollY + window.innerHeight === document.body.offsetHeight) {
			setFixedHeader(true);
			setScrollDown(true);
			setScrollDownMobile(true);
		} else {
			setFixedHeader(true);
			setScrollDown(true);
			setScrollDownMobile(true);
		}
	}, [scrollY]);

	// toggle side menu
	const toggleMenu = boolean => {
		if (boolean === true) {
			document.getElementsByTagName('body')[0].classList.add('no-scroll');
			setOpenSideMenu(true);
		} else if (boolean === false) {
			document.getElementsByTagName('body')[0].classList.remove('no-scroll');
			setOpenSideMenu(false);
		} else {
			document.getElementsByTagName('body')[0].classList.toggle('no-scroll');
			setOpenSideMenu(!openSideMenu);
		}
	};
	// close side menu after pathname changed
	useEffect(() => {
		toggleMenu(false);
	}, [pathname]);

	// open SideMenu when showLoginBox is true in mobile
	useEffect(() => {
		if (showLoginBox && media === 'phone') {
			toggleMenu(true);
		}
	}, [showLoginBox]);

	// close LoginBox when SideMenu is closed in mobile
	useEffect(() => {
		if (!openSideMenu && media === 'phone') {
			toggleLoginBox(false);
		}
	}, [openSideMenu]);

	// animated logo (lottie) config
	const [animationData, setAnimationData] = useState(null);
	const lottieOpt = {
		animationData,
		loop: false,
		autoplay: true,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
	const logoQuote = activePage === 'quote' ? LogoQuote : Logo;
	useEffect(() => {
		setAnimationData(AnimatedLogoJson);
	}, []);

	// animated logo (lottie) scroll style
	const [logoTop, setLogoTop] = useState(80);
	const [logoWidth, setLogoWidth] = useState(180);
	const [logoHeight, setLogoHeight] = useState(100);
	const scrollPercent = scrollY / 104;
	useEffect(() => {
		if (scrollY < 104) {
			setLogoTop(scrollPercent * (32 - 80) + 80);
			setLogoWidth(scrollPercent * (80 - 180) + 180);
			setLogoHeight(scrollPercent * (36 - 100) + 100);
		} else {
			setLogoTop(32);
			setLogoWidth(80);
			setLogoHeight(36);
		}
	}, [scrollY]);

	// close login box when click outside of desktop LoginBox
	const loginBoxRef = useOnclickOutside(() => {
		if (showLoginBox && media === 'desktop') {
			toggleLoginBox(false);
		}
	});

	// open member box
	const [openMemberBox, setOpenMemberBox] = useState(false);
	const memberBoxRef = useOnclickOutside(() => {
		setOpenMemberBox(false);
	});

	useEffect(() => {
		setOpenMemberBox(false);
		toggleLoginBox(false);
	}, [isMember, pathname]);

	// on click user icon
	function onClickUser() {
		checkMemberToken();
		if (isMember) {
			setOpenMemberBox(true);
		} else {
			toggleLoginBox(true);
		}
	}

	return (
		<>
			<header
				className={classnames(
					styles.header,
					{
						[styles.isNotAtHomePage]: activePage !== 'home' && activePage !== 'quote',
						[styles.fixedHeader]: fixedHeader && activePage === 'home',
						[styles.quoteHeader]: activePage === 'quote',
					},
					className,
				)}
			>
				<div className={styles.left}>
					<Navigation className={styles.nav} activeClassName={styles.navActive} />
				</div>
				{activePage === 'home' && !scrollDown && (
					// eslint-disable-next-line jsx-a11y/control-has-associated-label
					<button
						className={classnames(styles.circle, {
							[styles.transparent]: transparentMenuIcon,
						})}
						type="button"
						onMouseEnter={() => (media === 'desktop' ? setTransparentMenuIcon(true) : null)}
						onMouseLeave={() => (media === 'desktop' ? setTransparentMenuIcon(false) : null)}
						onClick={toggleMenu}
					>
						<div className={styles.visible} />
					</button>
				)}
				<div className={styles.right}>
					<div className={styles.language}>
						<Language lan={lan} pathname={pathname} activePage={activePage} />
					</div>
					<div className={styles.search}>
						<SearchButton activePage={activePage} />
					</div>
					<button
						className={classnames(styles.user, { [styles.loggedIn]: isMember })}
						onClick={() => onClickUser()}
						type="button"
					>
						<UserIcon />
						{showLoginBox && !isMember && (
							<button
								className={styles.loginBox}
								ref={loginBoxRef}
								onClick={e => e.stopPropagation()}
								type="button"
							>
								<LoginBox logIn={logIn} />
							</button>
						)}
						{openMemberBox && isMember && (
							<button
								className={styles.memberBox}
								ref={memberBoxRef}
								onClick={e => e.stopPropagation()}
								type="button"
							>
								<MemberBox isMember={isMember} logOut={logOut} />
							</button>
						)}
					</button>
					<button
						className={classnames(
							styles.menu,
							{
								[styles.orange]: transparentMenuIcon,
							},
							{ [styles.gray]: activePage !== 'home' || scrollDown },
						)}
						onMouseEnter={() => media === 'desktop' && setTransparentMenuIcon(true)}
						onClick={toggleMenu}
						type="button"
					>
						<MenuIcon />
					</button>
				</div>
			</header>
			{activePage === 'home' && (
				<Link
					to="/tw"
					otherLan
					style={{ top: logoTop, width: logoWidth, height: logoHeight }}
					className={styles.logo}
				>
					<div className={classnames(styles.img, { [styles.transparent]: scrollDown })}>
						<Lottie options={lottieOpt} isStopped={false} isPaused={false} />
					</div>
				</Link>
			)}
			<Link
				to="/tw"
				otherLan
				style={activePage === 'home' ? { top: logoTop, width: logoWidth, height: logoHeight } : {}}
				className={classnames(styles.logo, { [styles.small]: activePage !== 'home' })}
			>
				<img
					className={classnames(styles.img, {
						[styles.transparent]: !scrollDown && activePage === 'home',
					})}
					src={logoQuote}
					alt="Tlife"
				/>
			</Link>
			<Link
				to="/tw"
				otherLan
				className={classnames(
					styles.mobileLogo,
					{ [styles.scrollDown]: scrollDownMobile },
					{ [styles.scrollDown]: activePage !== 'home' },
				)}
			>
				<img className={styles.img} src={logoQuote} alt="Tlife" />
				<div className={styles.text}>在移動中遇見美好</div>
			</Link>
			<SideMenu
				className={classnames(styles.sideMenu, {
					[styles.openSideMenu]: openSideMenu,
				})}
				close={toggleMenu}
				subcategory={subcategory}
				region={region}
				magazine={magazine}
				isMember={isMember}
				fetchMagazine={fetchMagazine}
				logIn={logIn}
				logOut={logOut}
				showLoginBox={showLoginBox}
				toggleLoginBox={toggleLoginBox}
			/>
		</>
	);
};

export default Header;
