import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import Link from 'components/atoms/Link';
import Button from 'components/atoms/Button';
import UserIcon from 'images/icon/user-black.inline.svg';
import MemberLogo from 'images/icon/member-logo.inline.svg';
import LoginWarning from 'images/icon/login-warning.inline.svg';
import styles from './index.css';

function regexTest(str, reg) {
	const regex = new RegExp(reg);
	return regex.test(str);
}

const LoginBox = ({ logIn }) => {
	const [isTGOMemberError, setIsTGOMemberError] = useState(false);
	const [account, setAccount] = useState('');
	const [invalidAccount, setInvalidAccount] = useState(false);

	const inputRef = useRef(null);

	function confirmAccount(acc) {
		logIn(acc);
		setTimeout(() => {
			if (acc.length >= 2 && regexTest(acc, /^\d/)) {
				setIsTGOMemberError(true);
			}
			setInvalidAccount(true);
		}, 2000);
	}

	function typeAccount(e) {
		const { value } = e.target;

		setIsTGOMemberError(false);
		setInvalidAccount(false);

		/**
		 * 英文字母需直接轉換為大寫
		 * 前 1 為英文字：身分證號檢核，長度限 10
		 * 前 2 為英文字：會員卡號離線檢核，長度限 10
		 * 前 1 為數字：手機號碼檢核，前兩碼需為 09，長度限 10
		 */

		if (value.length > 0 && !regexTest(value, /^[A-Za-z0-9].*$/)) {
			setInvalidAccount(true);
		}

		/* 驗身分證字號 */
		if (
			value.length >= 2 &&
			regexTest(value, /^[A-Za-z]\d/) &&
			!regexTest(value.slice(2), /^[0-9]*$/)
		) {
			setInvalidAccount(true);
		}

		/* 驗會員卡號 */
		if (
			value.length >= 2 &&
			regexTest(value, /^[A-Za-z][A-Za-z]/) &&
			!regexTest(value.slice(2), /^[0-9]*$/)
		) {
			setInvalidAccount(true);
		}

		/* 驗手機號碼 */
		if (value.length >= 2 && regexTest(value, /^\d/) && !regexTest(value, /^09\d*$/)) {
			setInvalidAccount(true);
		}

		inputRef.current.value = value.toUpperCase();
		setAccount(value.toUpperCase());

		if (e.key === 'Enter') {
			confirmAccount(account);
		}
	}

	return (
		<div className={styles.login}>
			<div className={styles.triangle} />
			<div className={styles.wrap}>
				<div className={styles.memberLogo}>
					<MemberLogo />
				</div>
				<div className={styles.slogan}>收藏文章隨時看</div>
				<div className={styles.ctas}>
					<div className={styles.account}>帳號（身分證字號/會員卡號/手機號碼）</div>
					<div className={classnames(styles.input, { [styles.invalid]: invalidAccount })}>
						<UserIcon />
						<input onKeyUp={e => typeAccount(e)} ref={inputRef} maxLength="10" type="text" />
					</div>
					{invalidAccount && (
						<div className={styles.error}>
							<LoginWarning />
							{isTGOMemberError
								? '請加入TGo會員，或於TGo會員專區完成手機認證'
								: '輸入錯誤，請再試一次'}
						</div>
					)}
					<Button
						onClick={() => confirmAccount(account)}
						label="確認"
						type="normal"
						className={styles.send}
					/>
					<div className={styles.signUp}>
						<span>還不是會員？</span>
						<Link
							to="https://tgo.thsrc.com.tw/member.html"
							newTab
							otherWebsite
							className={styles.link}
						>
							加入我們
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginBox;
