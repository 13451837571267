import React from 'react';
import classnames from 'classnames';

import ArrowUpIcon from 'images/icon/arrow-up.inline.svg';

import styles from './index.css';

const ScrollUpButton = ({ className }) => {
	const scrollUP = () => {
		const smoothscroll = () => {
			const currentScroll = window.scrollY;
			if (currentScroll > 0) {
				window.requestAnimationFrame(smoothscroll);
				window.scrollTo(0, currentScroll - 100);
			}
		};
		smoothscroll();
	};

	return (
		<button className={classnames(styles.button, className)} type="button" onClick={scrollUP}>
			<ArrowUpIcon />
		</button>
	);
};

export default ScrollUpButton;
