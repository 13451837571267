import React from 'react';
import { useMedia } from 'util/hook/useMedia';
import Link from 'components/atoms/Link';
import Logo from 'images/icon/logo-thsr.inline.svg';
import Facebook from 'images/icon/facebook-footer.inline.svg';
import Youtube from 'images/icon/youtube.inline.svg';
import Internet from 'images/icon/internet.inline.svg';
import DownloadApp from 'images/icon/download-app.inline.svg';
import DownloadGoogle from 'images/icon/download-google.inline.svg';
import styles from './index.css';

const Footer = () => {
	const media = useMedia();

	return (
		<footer className={styles.footer}>
			{media === 'desktop' && (
				<div className={styles.inner}>
					<div className={styles.leftInfo}>
						<Link to="https://www.thsrc.com.tw/" className={styles.logo} newTab otherWebsite>
							<Logo />
						</Link>
						<div>
							<div className={styles.links}>
								<Link className={styles.link} to="/tw/copyright" otherLan>
									版權聲明
								</Link>
								<div className={styles.divide} />
								<Link
									className={styles.link}
									to="https://www.thsrc.com.tw/ArticleContent/d1fa3bcb-a016-47e2-88c6-7b7cbed00ed5"
									otherWebsite
									newTab
								>
									隱私權政策
								</Link>
							</div>
							<div className={styles.rights}>
								台灣高速鐵路股份有限公司 © Taiwan High Speed Rail Corporation. All Rights Reserved.
							</div>
						</div>
					</div>
					<div className={styles.rightInfo}>
						<div>
							<div className={styles.title}>Follow Us</div>
							<Link
								className={styles.link}
								to="https://www.facebook.com/thsrco"
								newTab
								otherWebsite
							>
								<Facebook />
								<div className={styles.platform}>Facebook</div>
							</Link>
							<Link
								className={styles.link}
								to="https://www.youtube.com/user/THSRFan"
								newTab
								otherWebsite
							>
								<Youtube />
								<div className={styles.platform}>Youtube</div>
							</Link>
							<Link className={styles.link} to="https://www.thsrc.com.tw/" newTab otherWebsite>
								<Internet />
								<div className={styles.platform}>台灣高鐵</div>
							</Link>
						</div>
						<div className={styles.leftMargin}>
							<div className={styles.title}>立即下載 台灣高鐵 App</div>
							<Link
								className={styles.download}
								to="https://apps.apple.com/us/app/apple-store/id1266626655"
								newTab
								otherWebsite
							>
								<DownloadApp />
							</Link>
							<Link
								className={styles.download}
								to="https://play.google.com/store/apps/details?id=tw.com.thsrc.enterprise"
								newTab
								otherWebsite
							>
								<DownloadGoogle />
							</Link>
						</div>
					</div>
				</div>
			)}
			{media === 'phone' && (
				<div className={styles.innerPhone}>
					<Link to="https://www.thsrc.com.tw/" className={styles.logo} newTab otherWebsite>
						<Logo />
					</Link>
					<div className={styles.centerInfo}>
						<div>
							<div className={styles.title}>Follow Us</div>
							<Link
								className={styles.link}
								to="https://www.facebook.com/thsrco"
								newTab
								otherWebsite
							>
								<Facebook />
								<div className={styles.platform}>Facebook</div>
							</Link>
							<Link
								className={styles.link}
								to="https://www.youtube.com/user/THSRFan"
								newTab
								otherWebsite
							>
								<Youtube />
								<div className={styles.platform}>Youtube</div>
							</Link>
							<Link className={styles.link} to="https://www.thsrc.com.tw/" newTab otherWebsite>
								<Internet />
								<div className={styles.platform}>台灣高鐵</div>
							</Link>
						</div>
						<div className={styles.leftMargin}>
							<div className={styles.title}>立即下載 台灣高鐵 App</div>
							<Link
								className={styles.download}
								to="https://apps.apple.com/us/app/apple-store/id1266626655"
								newTab
								otherWebsite
							>
								<DownloadApp />
							</Link>
							<Link
								className={styles.download}
								to="https://play.google.com/store/apps/details?id=tw.com.thsrc.enterprise"
								newTab
								otherWebsite
							>
								<DownloadGoogle />
							</Link>
						</div>
					</div>
					<div>
						<div className={styles.links}>
							<Link className={styles.link} to="/tw/copyright" otherLan>
								版權聲明
							</Link>
							<div className={styles.divide} />
							<Link
								className={styles.link}
								to="https://www.thsrc.com.tw/ArticleContent/d1fa3bcb-a016-47e2-88c6-7b7cbed00ed5"
								otherWebsite
								newTab
							>
								隱私權政策
							</Link>
						</div>
						<div className={styles.rights}>
							台灣高速鐵路股份有限公司 © Taiwan High Speed Rail Corporation. All Rights Reserved.
						</div>
					</div>
				</div>
			)}
		</footer>
	);
};

export default Footer;
