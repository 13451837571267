import React, { useState } from 'react';
import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { useHistory } from 'models/routing';
import { searchCSE } from 'util/helper';
import SearchIcon from 'images/icon/search-black.inline.svg';
import styles from './index.css';

const SearchButton = ({ activePage }) => {
	const history = useHistory();
	const [showSearch, setShowSearch] = useState(false);
	SearchButton.handleClickOutside = () => setShowSearch(false);

	return (
		<button
			type="button"
			className={classnames(
				styles.search,
				{ [styles.show]: showSearch },
				{ [styles.quote]: activePage === 'quote' },
			)}
			onClick={() => setShowSearch(true)}
		>
			<div className={styles.icon}>
				<SearchIcon />
			</div>
			<input
				className={classnames(styles.input, { [styles.show]: showSearch })}
				type="text"
				placeholder="搜尋"
				onKeyPress={e => {
					searchCSE(e, history);
				}}
			/>
		</button>
	);
};

const clickOutsideConfig = {
	handleClickOutside: () => SearchButton.handleClickOutside,
};

export default onClickOutside(SearchButton, clickOutsideConfig);
