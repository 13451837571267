import React from 'react';

import Loading from 'components/atoms/Loading';

import styles from './index.css';

const LoadingLayout = () => (
	<div className={styles.loading}>
		<Loading className={styles.loading} />
	</div>
);

export default LoadingLayout;
