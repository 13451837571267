import { createAction, handleActions } from 'redux-actions';
import { createContext, useContext } from 'react';

import { useRedux } from 'util/hook/redux';

import history from 'store/history';

export const routeChange = createAction('ROUTE_LOCATION_CHANGE', location => location);

export const historyPush = createAction(
	'HISTORY_PUSH',
	({ pathname = '', search = '', lan = '', state = {}, withLan = true }) => (
		dispatch,
		getState,
	) => {
		const {
			routing: { pathname: prevPathname, search: prevSearch },
			i18n: { lan: prevLan },
		} = getState();

		let nextPathname = pathname;
		if (lan) {
			if (!pathname || pathname === '/') {
				nextPathname = `/${lan}`;
			} else {
				nextPathname = `/${lan}${pathname}`;
			}
		} else if (withLan && pathname && pathname !== '/') {
			nextPathname = `/${prevLan}${pathname}`;
		}

		history.push({
			pathname: nextPathname,
			search,
			state: {
				prevPathname,
				prevSearch,
				...state,
			},
		});
	},
);

const reducer = {
	routing: handleActions(
		{
			ROUTE_LOCATION_CHANGE: (state, action) => ({
				...state,
				...action.payload,
			}),
		},
		{ ...history.location },
	),
};

export default { reducer };

export const HistoryContext = createContext({
	location: { pathname: '/' },
});

export const useHistory = () => useContext(HistoryContext);

const mapHooksToState = state => ({
	...state.routing,
});

export const useRouting = () => useRedux(mapHooksToState, { historyPush });
