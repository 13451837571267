import React from 'react';
import { storeActivePage } from 'models/common';

export default {
	path: '/search',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'search' */ './component')],
			render: ([Search]) => <Search />,
			onEnter: async ({ store }) => {
				console.log('on Enter search');
				await store.dispatch(storeActivePage('search'));
				console.log('on Enter search / end');
			},
		},
	],
};
