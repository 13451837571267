import { createAction, handleActions } from 'redux-actions';

import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

export const fetchMagazineArticle = createAction(
	'FETCH_MAGAZINE_ARTICLE',
	(id, page = 1, per_page = 9) => async () => {
		const { status, message, data } = await wrapFetch(
			`magazine/${id}`,
			{
				method: 'GET',
			},
			{ page, per_page },
		);

		if (!data.magazine) {
			throw new Error('isEmptyData');
		}

		if (status !== 200) {
			throw new Error(message);
		}

		return data;
	},
);

const reducer = {
	magazine: handleActions(
		{
			FETCH_MAGAZINE_ARTICLE_FULFILLED: (state, action) => ({
				...state,
				posts: action.payload.posts,
				magazine: action.payload.magazine,
			}),
		},
		{
			posts: {},
			magazine: {},
		},
	),
};

const selectMagazine = state => ({
	...state.magazine,
});

export const useMagazine = () => useRedux(selectMagazine, { fetchMagazineArticle });

export default { reducer };
