import React from 'react';

import SEO from 'components/atoms/SEO';

import history from 'store/history';

import classnames from 'classnames';
import styles from './index.css';

const NotFound = lan => {
	const langList = {
		tw: {
			title: (
				<>
					很抱歉 <br /> 您所查詢的網頁連結不存在
				</>
			),
			content: '您輸入的網址不存在或頁面資訊已過期，造成您的不便，敬請見諒。',
			button: '返回首頁',
		},
		en: {
			title: (
				<>
					Sorry, <br /> the URL doesn’t exist or the page has expired.
				</>
			),
			button: 'Return to homepage',
		},
		jp: {
			title: (
				<>
					大変申し訳ありません <br /> 入力した URL が存在しないか、ページの有効期限が切れています。
				</>
			),
			button: 'ホームページに戻る',
		},
	};

	const selectedLang = langList[lan.lan] || langList.tw;

	const handleOnClick = () => {
		const languageRoutes = {
			en: '/en/article-list/0?page=1',
			jp: '/jp/article-list/0?page=1',
		};

		const route = languageRoutes[lan.lan] || '/';
		history.push(route);
	};

	return (
		<div className={styles.copyright}>
			<div className={styles.wrapper}>
				<h1
					className={classnames(styles.title, {
						[styles.en]: lan.lan === 'en',
						[styles.jp]: lan.lan === 'jp',
					})}
				>
					{selectedLang.title}
				</h1>

				<div className={styles.line} />

				<p className={styles.text}>{selectedLang.content}</p>

				<button type="button" className={styles.button} onClick={handleOnClick}>
					{selectedLang.button}
				</button>
			</div>
			<SEO title="Not Found" />
		</div>
	);
};

export default NotFound;
