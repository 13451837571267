import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useRouting } from 'models/routing';
import OG from 'images/icon/og-default.jpeg';
import AppleTouchIcon from 'images/icon/apple-touch-icon.jpeg';

const { SELF_HOST_ENDPOINT } = process.env;

const SEO = ({
	description = '「TLife」為探索台灣之美的質感指南。透過深度細緻觀察，探尋台灣在地景點、美食好味、人文藝術，除了能輕鬆閱讀台灣高鐵車上刊物實體雜誌《TLife》的相同選文、收藏與分享文章，還有獨享的特別企畫單元、影音，讓您的日常成為旅行，每一次的移動都遇見美好感動！',
	title = '台灣高鐵車上刊物TLife',
	image = `${SELF_HOST_ENDPOINT}${OG.slice(2)}`,
	datePublished,
	dateModified,
	paths = [],
	images,
	imagesLocation,
}) => {
	const [{ pathname }] = useRouting();

	// resize image
	const width = 500;
	const [height, setHeight] = useState(500);
	useEffect(() => {
		const img = new Image();
		img.addEventListener('load', () => {
			setHeight(parseInt((img.naturalHeight / img.naturalWidth) * width, 10));
		});
		img.src = image;
	}, []);

	const articleSchema = {
		'@context': 'https://schema.org',
		'@type': 'Article',
		headline: title,
		description,
		image: [...image, ...(images || []), ...(imagesLocation || [])],
		datePublished,
		dateModified,
	};

	let breadCrumbsSchema = null;
	if (paths.length > 0) {
		breadCrumbsSchema = {
			'@context': 'https://schema.org',
			'@type': 'BreadcrumbList',
			itemListElement: paths.map((breadcrumb, index) => ({
				'@type': 'ListItem',
				position: index + 1,
				name: breadcrumb.name,
				item: `${SELF_HOST_ENDPOINT}${breadcrumb.value}`,
			})),
		};
	}

	return (
		<Helmet title={title}>
			<meta name="description" content={description} />
			<meta name="image" content={image} />
			<meta property="og:url" content={`${SELF_HOST_ENDPOINT}${pathname}`} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={image} />
			<meta property="og:image:secure_url" content={image} />
			<meta property="og:image:width" content={width} />
			<meta property="og:image:height" content={height} />
			<link rel="apple-touch-icon" href={`${SELF_HOST_ENDPOINT}${AppleTouchIcon.slice(2)}`} />
			{datePublished && (
				<script type="application/ld+json">{JSON.stringify(articleSchema, null, 2)}</script>
			)}
			{breadCrumbsSchema && (
				<script type="application/ld+json">{JSON.stringify(breadCrumbsSchema, null, 2)}</script>
			)}
		</Helmet>
	);
};

export default SEO;
