import React from 'react';
import Link from 'components/atoms/Link';
import styles from './index.css';

const MemberBox = ({ logOut }) => {
	return (
		<div className={styles.member}>
			<div className={styles.triangle} />
			<div className={styles.wrap}>
				<Link to="/bookmark?page=1" className={styles.option}>
					我的收藏
				</Link>
				<Link onClick={() => logOut()} to="/tw" otherLan className={styles.option}>
					登出
				</Link>
			</div>
		</div>
	);
};

export default MemberBox;
