import React from 'react';
import { storeActivePage } from 'models/common';

export default {
	path: '/copyright',
	components: () => [import(/* webpackChunkName: 'copyright' */ './component')],
	render: ([Copyright]) => <Copyright />,
	onEnter: async ({ store }) => {
		console.log('on Enter copyright');
		await store.dispatch(storeActivePage('copyright'));
		console.log('on Enter copyright / end');
	},
};
