import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeBlogDetail } from 'models/blog';

export default {
	path: '/article',
	render: (_, children) => children,
	children: [
		{
			path: '/:id',
			components: () => [import(/* webpackChunkName: 'article' */ './component')],
			render: ([Article]) => <Article />,
			onEnter: async ({ store, params: { id } }) => {
				console.log('on Enter article');
				const token = new URLSearchParams(window.location.search).get('token');
				await store.dispatch(initializeBlogDetail(id, token));
				await store.dispatch(storeActivePage(`article/${id}`));
				console.log('on Enter article / end');
			},
		},
	],
};
