import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

export const fetchBanner = createAction('FETCH_BANNER', async () => {
	const { status, message, data } = await wrapFetch('banner', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data.banners;
});

export const fetchNews = createAction('FETCH_NEWS', async () => {
	const { status, message, data } = await wrapFetch('post/news', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data.posts;
});

export const fetchSpecial = createAction('FETCH_SPECIAL', async () => {
	const page = 1;
	const perPage = 3;
	const { status, message, data } = await wrapFetch(
		`plan`,
		{
			method: 'GET',
		},
		{ page, per_page: perPage },
	);

	if (status !== 200) {
		throw new Error(message);
	}

	return data.plans.data;
});

export const fetchFocus = createAction('FETCH_FOCUS', async () => {
	const { status, message, data } = await wrapFetch('post/focus', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data.posts;
});

export const fetchQuote = createAction('FETCH_QUOTE', async () => {
	const { status, message, data } = await wrapFetch('quote/home', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data.quote;
});

export const fetchDesktopMagazine = createAction(
	'FETCH_DESKTOP_MAGAZINE',
	(page = 1) => async () => {
		const perPage = 10;
		const { status, message, data } = await wrapFetch(
			'magazine',
			{
				method: 'GET',
			},
			{ page, per_page: perPage },
		);

		if (status !== 200) {
			throw new Error(message);
		}

		return data.magazines;
	},
);

export const fetchMobileMagazine = createAction(
	'FETCH_MOBILE_MAGAZINE',
	(page = 1) => async (dispatch, getState) => {
		const {
			home: { mobileMag, mobileMagList },
		} = getState();

		if (page <= mobileMag.current_page) {
			return { mobileMag, mobileMagList };
		}

		const perPage = 10;
		const { status, message, data } = await wrapFetch(
			'magazine',
			{
				method: 'GET',
			},
			{ page, per_page: perPage },
		);

		if (status !== 200) {
			throw new Error(message);
		}

		const { magazines } = data;
		const newMobileMagList = mobileMagList.concat(magazines.data);

		return { mobileMag: magazines, mobileMagList: newMobileMagList };
	},
);

export const initializeHome = createAction('INITIALIZE_HOME', () => async dispatch => {
	dispatch(fetchBanner());
	dispatch(fetchNews());
	dispatch(fetchSpecial());
	dispatch(fetchFocus());
	dispatch(fetchQuote());
	dispatch(fetchDesktopMagazine());
	dispatch(fetchMobileMagazine());
});

const reducer = {
	home: handleActions(
		{
			FETCH_BANNER_FULFILLED: (state, action) => ({
				...state,
				banner: action.payload,
			}),
			FETCH_NEWS_FULFILLED: (state, action) => ({
				...state,
				news: action.payload,
			}),
			FETCH_SPECIAL_FULFILLED: (state, action) => ({
				...state,
				special: action.payload,
			}),
			FETCH_FOCUS_FULFILLED: (state, action) => ({
				...state,
				focus: action.payload,
			}),
			FETCH_QUOTE_FULFILLED: (state, action) => ({
				...state,
				quote: action.payload,
			}),
			FETCH_DESKTOP_MAGAZINE_FULFILLED: (state, action) => ({
				...state,
				desktopMag: action.payload,
			}),
			FETCH_MOBILE_MAGAZINE_FULFILLED: (state, action) => ({
				...state,
				mobileMag: action.payload.mobileMag,
				mobileMagList: action.payload.mobileMagList,
			}),
		},
		{
			banners: [],
			brands: [],
			banner: [],
			news: [],
			special: [],
			focus: [],
			quote: {},
			desktopMag: {},
			mobileMag: {},
			mobileMagList: [],
		},
	),
};

const selectHome = state => ({
	...state.home,
});

export const useHome = () => useRedux(selectHome, { fetchDesktopMagazine, fetchMobileMagazine });

export default { reducer };
