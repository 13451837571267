/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import BackdropFilter from 'react-backdrop-filter';
import { useHistory, useRouting } from 'models/routing';
import { searchCSE } from 'util/helper';
import { useMedia } from 'util/hook/useMedia';
import Language from 'components/molecules/Language';
import Navigation from 'components/molecules/Navigation';
import LoginBox from 'components/molecules/LoginBox';
import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import CloseIcon from 'images/icon/close.inline.svg';
import SearchIcon from 'images/icon/search-white.inline.svg';
import UserIcon from 'images/icon/user-white.inline.svg';
import LoginBack from 'images/icon/login-back.inline.svg';
import styles from './index.css';

const SideMenu = ({
	className,
	close,
	region,
	subcategory,
	magazine,
	isMember,
	fetchMagazine,
	logIn,
	logOut,
	showLoginBox,
	toggleLoginBox,
}) => {
	const history = useHistory();
	const media = useMedia();
	const [{ pathname }] = useRouting();
	const lan = pathname.split('/')[1];

	const Genre = ({ children, data, type }) => {
		const [toggle, setToggle] = useState(false);
		return (
			<>
				<Button className={styles.genre} onClick={() => setToggle(!toggle)}>
					{children}
					<div className={styles.icon}>
						<div className={styles.x} />
						{!toggle && <div className={styles.y} />}
					</div>
				</Button>
				{data && toggle && (
					<div className={styles.listBox}>
						{data.map(i => {
							return (
								<Link
									to={`/tw/article-list/${i.id}?type=${type}&page=1`}
									otherLan
									className={styles.list}
									key={i.id}
								>
									{i.name}
								</Link>
							);
						})}
					</div>
				)}
			</>
		);
	};

	// get more magazine
	const [magPage, setMagPage] = useState(1);
	const [magToggle, setMagToggle] = useState(false);

	useEffect(() => {
		fetchMagazine(1, magPage * 10);
		if (!magToggle) {
			setMagPage(1);
		}
	}, [magPage, magToggle]);

	// toggle login page
	function showLogin() {
		if (!isMember) {
			toggleLoginBox(true);
			close();
		}
	}

	const [showMobileLogin, setShowMobileLogin] = useState(false);
	const [removeLoginTrans, setRemoveLoginTrans] = useState(false);

	useEffect(() => {
		if (showLoginBox) {
			setShowMobileLogin(true);
		}
	}, [showLoginBox]);

	function closeMobileLogin() {
		if (!isMember) {
			setRemoveLoginTrans(true);
			close();
			setTimeout(() => {
				setShowMobileLogin(false);
				setRemoveLoginTrans(false);
			}, 500);
		}
	}

	const GenreMag = ({ children, data, magLastPage, expand }) => {
		return (
			<>
				<Button className={styles.genre} onClick={() => setMagToggle(!magToggle)}>
					{children}
					<div className={styles.icon}>
						<div className={styles.x} />
						{!expand && <div className={styles.y} />}
					</div>
				</Button>
				{data && expand && (
					<div className={styles.listBox}>
						{data.map(i => {
							return (
								<Link
									to={`/tw/magazine/${i.id}?page=1`}
									otherLan
									className={styles.list}
									key={i.id}
								>
									{i.issue}
								</Link>
							);
						})}
						{magLastPage !== 1 && (
							<button type="button" className={styles.more} onClick={() => setMagPage(magPage + 1)}>
								展開更多
							</button>
						)}
					</div>
				)}
			</>
		);
	};

	return (
		<div id="sidemenu" className={classnames(styles.sideMenu, className)}>
			<div
				className={styles.closeArea}
				onClick={close}
				role="button"
				tabIndex={0}
				onKeyPress={() => {}}
			/>
			<BackdropFilter
				className={classnames(
					styles.content,
					{
						[styles.noBackground]: showMobileLogin && !isMember && media === 'phone',
					},
					{
						[styles.noTransition]: showLoginBox && !isMember && media === 'phone',
					},
				)}
				filter="blur(20px)"
				html2canvasOpts={{
					allowTaint: true,
				}}
			>
				<div
					className={classnames(
						styles.menuArea,
						{
							[styles.transparent]: showMobileLogin && !isMember && media === 'phone',
						},
						{
							[styles.noTransition]: showLoginBox && !isMember && media === 'phone',
						},
					)}
				>
					<div className={styles.top}>
						{media === 'phone' ? (
							<Language lan={lan} pathname={pathname} />
						) : (
							<button
								className={classnames(styles.login, { [styles.loggedIn]: isMember })}
								onClick={() => showLogin()}
								type="button"
							>
								<div className={styles.icon}>
									<UserIcon />
								</div>
								<div>{isMember ? '已確認 TGo 會員' : '確認 TGo 會員'}</div>
							</button>
						)}
						<button type="button" onClick={close}>
							<CloseIcon />
						</button>
					</div>
					{media === 'phone' && (
						<div className={styles.user}>
							<button
								onClick={() => setShowMobileLogin(true)}
								type="button"
								className={classnames(styles.login, { [styles.loggedIn]: isMember })}
							>
								<div className={styles.icon}>
									<UserIcon />
								</div>
								<div>{isMember ? '已確認 TGo 會員' : '確認 TGo 會員'}</div>
							</button>
							{isMember && (
								<>
									<Link to="/bookmark?page=1" className={styles.memberOption} onClick={close}>
										我的收藏
									</Link>
									<Link to="/tw" otherLan className={styles.memberOption} onClick={() => logOut()}>
										登出
									</Link>
								</>
							)}
						</div>
					)}
					<div className={styles.search}>
						<div className={styles.icon}>
							<SearchIcon />
						</div>
						<input
							className={styles.input}
							type="text"
							placeholder="搜尋"
							onKeyPress={e => {
								searchCSE(e, history);
							}}
						/>
					</div>
					<div className={styles.scroll}>
						{media === 'phone' && <Navigation className={styles.nav} />}
						<GenreMag data={magazine.data} magLastPage={magazine.last_page} expand={magToggle}>
							雜誌
						</GenreMag>
						<Genre data={subcategory} type="child">
							主題
						</Genre>
						<Genre data={region} type="region">
							地區
						</Genre>
					</div>
				</div>
				{!isMember && media === 'phone' && (
					<BackdropFilter
						className={classnames(styles.loginArea, {
							[styles.open]: showMobileLogin,
							[styles.closeTest]: removeLoginTrans,
						})}
						filter="blur(54px)"
						html2canvasOpts={{
							allowTaint: true,
						}}
					>
						<div className={styles.top}>
							<button onClick={() => setShowMobileLogin(false)} type="button">
								<LoginBack isMember={isMember} logIn={logIn} />
							</button>
							<button type="button" className={styles.orange} onClick={() => closeMobileLogin()}>
								<CloseIcon />
							</button>
						</div>
						<LoginBox logIn={logIn} />
					</BackdropFilter>
				)}
			</BackdropFilter>
		</div>
	);
};

export default SideMenu;
