import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

export const cleanQuote = createAction('CLEAN_QUOTE');

export const getQuote = createAction('GET_QUOTE', async () => {
	const { data, status, message } = await wrapFetch('quote', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeQuote = createAction('INITIALIZE_QUOTE', () => async dispatch => {
	await dispatch(cleanQuote());
	await dispatch(getQuote());
});

const reducer = {
	quotes: handleActions(
		{
			GET_QUOTE_PENDING: state => ({
				...state,

				loading: true,
			}),

			GET_QUOTE_FULFILLED: (state, action) => ({
				...state,

				quotes: action.payload.quotes,
				loading: false,
			}),

			CLEAN_QUOTE: state => ({
				...state,

				quotes: [],
			}),
		},
		{
			quotes: [],
		},
	),
};

const selectQuote = state => ({
	...state.quotes,
});

export const useQuote = () => useRedux(selectQuote);

export default { reducer };
