import qs from 'qs';
import { getLangfromURL } from 'util/helper';

const { API_ENDPOINT } = process.env;

export const generateUrl = (url, params) => {
	const paramsString = qs.stringify(params, { arrayFormat: 'brackets' });

	const URL =
		paramsString !== '' ? `${API_ENDPOINT}/${url}?${paramsString}` : `${API_ENDPOINT}/${url}`;

	return URL;
};

export const wrapFetch = async (url, options = { headers: {} }, params = {}) => {
	const URL = generateUrl(url, params);

	const headers = new Headers({
		'Content-Type': 'application/json',
		Accept: 'application/json',
		...options.headers,
	});

	const result = await fetch(URL, { ...options, headers });

	return result.json();
};

export const wrapLangFetch = async (url, options = { headers: {} }, params = {}) => {
	return wrapFetch(
		url,
		{
			...options,
		},
		{ lang: getLangfromURL(), ...params },
	);
};
