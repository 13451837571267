const insertScript = (id, src, options = { async: true, defer: true }) => {
	if (document.getElementById(id)) {
		return 1;
	}

	const jss = document.getElementsByTagName('script')[0];
	const js = document.createElement('script');

	js.id = id;
	js.src = src;

	if (options.async) {
		js.async = true;
	}

	if (options.defer) {
		js.defer = true;
	}

	if (jss.parentNode === null) {
		throw Error('jss.parentNode is null');
	}

	jss.parentNode.insertBefore(js, jss);
	return 0;
};

window.fbAsyncInit = window.fbAsyncInit || {};

const { FACEBOOK_ID } = process.env;

export const loadFacebookLibrary = () =>
	new Promise(resolve => {
		window.fbAsyncInit = () => {
			window.FB.init({
				appId: FACEBOOK_ID,
				autoLogAppEvents: true,
				xfbml: true, // parse social plugins on this page
				version: 'v8.0', // use graph api version 8.0
			});
			resolve();
		};

		const result = insertScript(
			'facebook-jssdk',
			'//connect.facebook.net/zh_TW/sdk/xfbml.customerchat.js',
		);

		// Aready insert
		if (result === 1) {
			resolve();
		}
	});

const { GOOGLE_CLIENT_ID } = process.env;

export const loadGoogleLibrary = () =>
	new Promise(resolve => {
		window.onGoogleStart = () => {
			window.gapi.load('auth2', () => {
				window.auth2 = window.gapi.auth2.init({
					client_id: GOOGLE_CLIENT_ID,
					// Scopes to request in addition to 'profile' and 'email'
					scope: 'profile email',
				});
			});
			resolve();
		};

		const result = insertScript(
			'google-jssdk',
			'//apis.google.com/js/client:platform.js?onload=onGoogleStart',
		);

		// Aready insert
		if (result === 1) {
			resolve();
		}
	});
