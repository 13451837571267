import React, { useState, useEffect, useRef } from 'react';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import ScrollUpButton from 'components/atoms/ScrollUpButton';
import { useRouting } from 'models/routing';
import { useScroll } from 'util/hook/useScroll';
import { changeLng } from 'util/i18n';
import { useCommon } from 'models/common';
import styles from './index.css';

const App = ({ children }) => {
	const [scrollUpButtonDisplay, setScrollUpButtonDisplay] = useState(false);
	const scrollY = useScroll();
	const refFooter = useRef(null);
	const [{ pathname }] = useRouting();
	const [{ idHash }] = useCommon();
	const lan = pathname.split('/')[1] || 'tw';

	useEffect(() => {
		if (scrollY) {
			setScrollUpButtonDisplay(
				window.innerHeight + scrollY >= document.body.offsetHeight - refFooter.current.offsetHeight,
			);
		}
	}, [scrollY]);

	useEffect(() => {
		changeLng(lan);
	}, [lan]);

	useEffect(() => {
		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'user_id',
				user_id: idHash,
			});
		}
	}, [idHash]);

	return (
		<>
			<Header />
			<div className={styles.children}>{children}</div>
			<ScrollUpButton className={scrollUpButtonDisplay ? styles.active : styles.inActive} />
			<div ref={refFooter}>
				<Footer />
			</div>
		</>
	);
};

export default App;
