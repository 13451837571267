import React from 'react';
import { storeActivePage } from 'models/common';
import { fetchBookmarkArticle } from 'models/bookmark';

export default {
	path: '/bookmark',
	components: () => [import(/* webpackChunkName: 'bookmark' */ './component')],
	render: ([Bookmark]) => <Bookmark />,
	onEnter: async ({ store }) => {
		console.log('on Enter bookmark');
		await store.dispatch(storeActivePage('bookmark'));
		await store.dispatch(fetchBookmarkArticle());
		console.log('on Enter bookmark / end');
	},
};
