import React from 'react';

import { storeActivePage } from 'models/common';
import { initializeQuote } from 'models/quote';

export default {
	path: '/quote',
	components: () => [import(/* webpackChunkName: 'quote' */ './component')],
	render: ([QuoteList]) => <QuoteList />,
	onEnter: async ({ store }) => {
		console.log('on Enter quote');
		await store.dispatch(storeActivePage(`quote`));
		await store.dispatch(initializeQuote());
		console.log('on Enter quote / end');
	},
};
