import { lanList } from '../../config/i18n';

export const textShorten = (text, length) => {
	let newText = text;

	if (text.length >= length) {
		const lenght = length; // 超過 x 個字以 ... 取代
		newText = `${text.substring(0, lenght - 1)}...`;
	}

	return newText;
};

export const isExist = value => value !== null && value !== '' && typeof value !== 'undefined';

export const isEmpty = value => !isExist(value);

export const sleep = time => new Promise(resolve => setTimeout(() => resolve(), time));

// https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie
export const isInternetExplorer = () =>
	window.navigator.userAgent.indexOf('MSIE ') > 0 ||
	window.navigator.userAgent.indexOf('Trident/') > 0;

const { IMAGE_ENDPOINT } = process.env;

export const getImageUrl = filename => ({
	main: `${IMAGE_ENDPOINT}/${filename}`,
	thumbnail: `${IMAGE_ENDPOINT}/thumbnail/${filename}`,
});

export const groupBy = (xs, key) =>
	xs.reduce((rv, x) => {
		// eslint-disable-next-line no-param-reassign
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});

export const scrollToOffset = (offset = 0) => {
	window.scrollTo({
		behavior: 'smooth',
		top: offset,
	});
};

export const scrollToRef = (ref = null, offset = 0) => {
	if (ref && ref.current) {
		window.scrollTo(0, ref.current.offsetTop + offset);
	} else {
		window.scrollTo(0, ref.current.offsetTop + offset);
	}
};

export const scrollToRefSmooth = (ref = null, offset = 0) => {
	if (ref && ref.current) {
		window.scrollTo({
			behavior: 'smooth',
			top: ref.current.offsetTop + offset,
		});
	} else {
		window.scrollTo({
			behavior: 'smooth',
			top: offset,
		});
	}
};

export const getLangfromURL = () => {
	let lang = window.location.pathname.split('/')[1];

	if (!lanList.includes(lang)) {
		[lang] = lanList;
	}

	return lang;
};

export const getParameterByName = name => {
	const params = new URL(document.location).searchParams;
	return params.get(name);
};

// (https://dev.to/kevin940726/one-fun-trick-to-observe-elements-in-realtime-without-mutationobserver-22kj)
export const editAfterLoaded = (selector, callback) => {
	function queryElements(s, c) {
		const elements = document.querySelectorAll(s);
		elements.forEach(item => c(item));
	}
	queryElements(selector, callback);
	const observer = new MutationObserver(() => {
		queryElements(selector, callback);
	});
	observer.observe(document.documentElement, {
		attributes: true,
		childList: true,
		characterData: true,
		subtree: true,
	});
};

// (https://stackoverflow.com/a/58519810)
export const groupArray = (data, num) => {
	let newData = [];
	newData = data.reduce((r, e, i) => (i % num ? r[r.length - 1].push(e) : r.push([e])) && r, []);
	return newData;
};

export const searchCSE = (e, history) => {
	if (e.key === 'Enter') {
		const searchParams = e.target.value;
		history.push(`/tw/search?q=${searchParams}`, '_self');
		history.go(0);
	}
};

// (https://www.tutorialspoint.com/how-to-remove-html-tags-from-a-string-in-javascript)
export const removeHTMLTag = str => {
	if (str === null || str === '') return false;
	const newStr = str.toString();
	return newStr.replace(/(<([^>]+)>)/gi, '');
};

export const extractImageUrls = htmlContent => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlContent, 'text/html');

	// Extract image URLs
	const imgs = Array.from(doc.getElementsByTagName('img'));
	return imgs.map(img => img.src);
};
