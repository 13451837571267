import React from 'react';
import { storeActivePage } from 'models/common';
import { fetchMagazineArticle } from 'models/magazine';
import { getParameterByName, isEmpty, isExist } from 'util/helper';
import history from 'store/history';

export default {
	path: '/magazine/:id',
	components: () => [import(/* webpackChunkName: 'magazine' */ './component')],
	render: ([Magazine]) => <Magazine />,
	onEnter: async ({ store, params: { id } }) => {
		console.log('on Enter magazine');
		const lanIndex = window.location.href.indexOf('/magazine');
		const lanSlice = window.location.href.slice(0, lanIndex);
		const query = window.location.href.split('?')[1];
		const lan = lanSlice.substring(lanSlice.lastIndexOf('/') + 1);

		if (isEmpty(getParameterByName('page'))) {
			const redirectUrl = isExist(query)
				? `/${lan}/magazine/${id}?page=1&${query}`
				: `/${lan}/magazine/${id}?page=1`;
			history.push(redirectUrl);
		}
		await store.dispatch(storeActivePage(`magazine/${id}`));
		await store.dispatch(fetchMagazineArticle(id));
		console.log('on Enter magazine / end');
	},
};
