import React from 'react';

import { useHistory, useRouting } from 'models/routing';

const onClickHandler = (callback, history, newTab) => {
	return e => {
		if (!newTab) {
			e.preventDefault();
			history.push(`${e.currentTarget.pathname}${e.currentTarget.search}`);
		}
		callback(e);
	};
};

const Link = ({
	className,
	to = '',
	onClick = () => {},
	onMouseEnter = () => {},
	onmouseleave = () => {},
	children,
	newTab = false,
	otherWebsite = false,
	otherLan = false,
	...props
}) => {
	const history = useHistory();

	const [{ pathname }] = useRouting();
	const lan = pathname.split('/')[1] || 'tw';
	const href = lan !== 'tw' || otherWebsite || otherLan ? to : `/${lan}${to}`;

	return (
		<a
			className={className}
			// eslint-disable-next-line no-nested-ternary
			href={href}
			target={newTab ? '_blank' : '_self'}
			role="button"
			tabIndex={0}
			onClick={onClickHandler(onClick, history, newTab)}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onmouseleave}
			onKeyPress={() => {}}
			rel="noreferrer"
			/* eslint-disable react/jsx-props-no-spreading */
			{...props}
		>
			{children}
		</a>
	);
};

export default Link;
