import React from 'react';
import { storeActivePage } from 'models/common';
import { fetchSpecialDetail } from 'models/special';

export default {
	path: '/special/:id',
	components: () => [import(/* webpackChunkName: 'special' */ './component')],
	render: ([Special]) => <Special />,
	onEnter: async ({ store, params: { id } }) => {
		console.log('on Enter special');
		await store.dispatch(storeActivePage(`special/${id}`));
		await store.dispatch(fetchSpecialDetail(id));
		console.log('on Enter special / end');
	},
};
