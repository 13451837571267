import { createAction, handleActions } from 'redux-actions';

import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

import storage from 'util/storage';

export const fetchCategory = createAction('FETCH_CATEGORY', async () => {
	const { status, message, data } = await wrapFetch('category', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data.categories;
});

export const fetchSubcategory = createAction('FETCH_SUBCATEGORY', async () => {
	const { status, message, data } = await wrapFetch('subcategory', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data.subcategories;
});

export const fetchRegion = createAction('FETCH_REGION', async () => {
	const { status, message, data } = await wrapFetch('postRegion', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data.postRegions;
});

export const fetchMagazine = createAction('FETCH_MAGAZINE', (page, perPage) => async () => {
	const { status, message, data } = await wrapFetch(
		'magazine',
		{
			method: 'GET',
		},
		{ page, per_page: perPage },
	);

	if (status !== 200) {
		throw new Error(message);
	}

	return data.magazines;
});

export const toggleLoginBox = createAction('TOGGLE_LOGIN_BOX', status => status);

export const logOut = createAction('LOG_OUT', () => {
	storage.removeItem('memberToken');

	return { isMember: false, idHash: '' };
});

export const checkMemberToken = createAction('CHECK_MEMBER_TOKEN', () => async dispatch => {
	const params = {
		method: 'GET',
		headers: {
			authorization: `Bearer ${storage.getItem('memberToken')}`,
		},
	};

	const { data, status, message } = await wrapFetch('member/me', params);

	if (status !== 200 && status !== 401) {
		throw new Error(message);
	}

	if (status === 401) {
		await dispatch(logOut());
	}

	const isMember = status === 200;

	return { isMember, idHash: data.id_hash, savedArticle: data.post_ids };
});

export const logIn = createAction('LOG_IN', account => async dispatch => {
	const body = {
		account,
	};

	const { status, message, data } = await wrapFetch('login', {
		method: 'POST',
		body: JSON.stringify(body),
	});

	if (status !== 200) {
		throw new Error(message);
	}

	storage.setItem('memberToken', data.token);
	const isMember = status === 200;

	await dispatch(checkMemberToken());

	return { isMember, memberToken: data.token, idHash: data.id_hash };
});

export const saveArticle = createAction('SAVE_ARTICLE', id => async dispatch => {
	const params = {
		method: 'GET',
		headers: {
			authorization: `Bearer ${storage.getItem('memberToken')}`,
		},
	};

	const { status, message, data } = await wrapFetch(`post/${id}/keep`, params);

	if (status !== 200 && status !== 401) {
		throw new Error(message);
	}

	if (status === 401) {
		await dispatch(logOut());
	}

	return status === 200 ? data : [];
});

export const storeActivePage = createAction('STORE_ACTIVE_PAGE', page => page);

const reducer = {
	common: handleActions(
		{
			FETCH_CATEGORY_FULFILLED: (state, action) => ({
				...state,

				category: action.payload,
			}),
			FETCH_SUBCATEGORY_FULFILLED: (state, action) => ({
				...state,

				subcategory: action.payload,
			}),
			FETCH_REGION_FULFILLED: (state, action) => ({
				...state,

				region: action.payload,
			}),
			FETCH_MAGAZINE_FULFILLED: (state, action) => ({
				...state,
				magazine: action.payload,
			}),
			FETCH_IG_POST_FULFILLED: (state, action) => ({
				...state,

				igpost: action.payload,
			}),
			STORE_ACTIVE_PAGE: (state, action) => ({
				...state,

				activePage: action.payload,
			}),
			TOGGLE_LOGIN_BOX: (state, action) => ({
				...state,

				showLoginBox: action.payload,
			}),
			LOG_OUT: (state, action) => ({
				...state,

				isMember: action.payload.isMember,
				idHash: action.payload.idHash,
			}),
			CHECK_MEMBER_TOKEN_FULFILLED: (state, action) => ({
				...state,

				isMember: action.payload.isMember,
				idHash: action.payload.idHash,
				savedArticle: action.payload.savedArticle,
			}),
			LOG_IN_FULFILLED: (state, action) => ({
				...state,

				isMember: action.payload.isMember,
				memberToken: action.payload.memberToken,
				idHash: action.payload.idHash,
			}),
			SAVE_ARTICLE_FULFILLED: (state, action) => ({
				...state,

				savedArticle: action.payload,
			}),
		},
		{
			category: [],
			subcategory: [],
			region: [],
			magazine: {},
			igpost: [],
			activePage: '',
			showLoginBox: false,
			isMember: false,
			memberToken: '',
			idHash: '',
			savedArticle: [],
		},
	),
};

const selectCommon = state => ({
	...state.common,
});

export const useCommon = () =>
	useRedux(selectCommon, {
		fetchMagazine,
		toggleLoginBox,
		checkMemberToken,
		logIn,
		logOut,
		saveArticle,
	});

export default { reducer };
