import { combineReducers } from 'redux';

import routing from './routing';
import blog from './blog';
import special from './special';
import i18n from './i18n';
import home from './home';
import common from './common';
import quote from './quote';
import magazine from './magazine';
import bookmark from './bookmark';

const reducers = combineReducers({
	...routing.reducer,
	...blog.reducer,
	...special.reducer,
	...i18n.reducer,
	...home.reducer,
	...common.reducer,
	...quote.reducer,
	...magazine.reducer,
	...bookmark.reducer,
});

export default reducers;
