import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Link from 'components/atoms/Link';
import { useMedia } from 'util/hook/useMedia';
import styles from './index.css';

const Language = ({ lan, pathname, activePage }) => {
	const media = useMedia();

	const moveItem = (arr, from, to) => {
		arr.splice(to, 0, arr.splice(from, 1)[0]);
	};
	function lanButton() {
		const lansList = ['tw', 'en', 'jp'];
		const buttonList = ['中', 'EN', '日'];
		const buttons = buttonList;
		const lans = lansList;
		if (lan !== '') {
			moveItem(buttons, lansList.indexOf(lan), 0);
			moveItem(lans, lansList.indexOf(lan), 0);
		}
		return { lans, buttons };
	}

	const [toggle, setToggle] = useState(false);
	useEffect(() => {
		setToggle(false);
	}, [lan]);

	if (media === 'desktop') {
		return (
			<div className={styles.language}>
				<div
					className={styles.hoverArea}
					onMouseEnter={() => {
						setToggle(true);
					}}
					onMouseLeave={() => {
						setToggle(false);
					}}
				/>
				{(lan === '' || lan === lanButton().lans[0]) && (
					<button
						type="button"
						className={classnames(
							styles.link,
							{ [styles.show]: toggle },
							{ [styles.quote]: activePage === 'quote' },
						)}
						onClick={() => {
							setToggle(!toggle);
						}}
						onMouseEnter={() => {
							setToggle(true);
						}}
					>
						{lanButton().buttons[0]}
					</button>
				)}
				{pathname !== '/' && lan !== lanButton().lans[0] && (
					<Link
						otherLan={lan !== lanButton().lans[0]}
						to={
							lanButton().lans[0] !== 'tw'
								? `/${lanButton().lans[0]}/article-list/0?page=1`
								: `/${lanButton().lans[0]}`
						}
						className={classnames(
							styles.link,
							{ [styles.show]: toggle },
							{ [styles.quote]: activePage === 'quote' },
						)}
						onClick={() => {
							setToggle(!toggle);
						}}
					>
						{lanButton().buttons[0]}
					</Link>
				)}
				<Link
					to={
						lanButton().lans[1] !== 'tw'
							? `/${lanButton().lans[1]}/article-list/0?page=1`
							: `/${lanButton().lans[1]}`
					}
					otherLan={lan !== lanButton().lans[1]}
					className={classnames(
						styles.link,
						{ [styles.show]: toggle },
						{ [styles.quote]: activePage === 'quote' },
					)}
					onMouseEnter={() => {
						setToggle(true);
					}}
				>
					{lanButton().buttons[1]}
				</Link>
				<Link
					to={
						lanButton().lans[2] !== 'tw'
							? `/${lanButton().lans[2]}/article-list/0?page=1`
							: `/${lanButton().lans[2]}`
					}
					otherLan={lan !== lanButton().lans[2]}
					className={classnames(
						styles.link,
						{ [styles.show]: toggle },
						{ [styles.quote]: activePage === 'quote' },
					)}
					onMouseEnter={() => {
						setToggle(true);
					}}
				>
					{lanButton().buttons[2]}
				</Link>
			</div>
		);
	}
	return (
		<div className={styles.language}>
			<Link
				className={styles.link}
				onClick={() => {
					setToggle(!toggle);
				}}
				otherLan
				to={
					lanButton().lans[0] !== 'tw'
						? `/${lanButton().lans[0]}/article-list/0?page=1`
						: `/${lanButton().lans[0]}`
				}
			>
				{lanButton().buttons[0]}
			</Link>
			<Link
				to={
					lanButton().lans[1] !== 'tw'
						? `/${lanButton().lans[1]}/article-list/0?page=1`
						: `/${lanButton().lans[1]}`
				}
				otherLan
				className={classnames(styles.link, styles.opacity)}
			>
				{lanButton().buttons[1]}
			</Link>
			<Link
				to={
					lanButton().lans[2] !== 'tw'
						? `/${lanButton().lans[2]}/article-list/0?page=1`
						: `/${lanButton().lans[2]}`
				}
				otherLan
				className={classnames(styles.link, styles.opacity)}
			>
				{lanButton().buttons[2]}
			</Link>
		</div>
	);
};

export default Language;
