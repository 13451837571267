import React from 'react';
import classnames from 'classnames';
import { useCommon } from 'models/common';
import Link from 'components/atoms/Link';
import styles from './index.css';

const Navigator = ({ className, activeClassName, children, itemOnClick, useActive = true }) => {
	const [{ category, activePage }] = useCommon();

	return (
		<nav className={classnames(styles.navigator, className)}>
			<ul>
				{category.map(item => (
					<li
						className={
							useActive && activePage === `article-list/${item.id}`
								? classnames(styles.active, activeClassName)
								: ''
						}
						key={item.id}
					>
						<Link to={`/tw/article-list/${item.id}?page=1`} otherLan onClick={itemOnClick}>
							{item.name}
						</Link>
					</li>
				))}
				<li
					className={
						useActive && activePage === 'quote' ? classnames(styles.active, activeClassName) : ''
					}
				>
					<Link to="/tw/quote" otherLan onClick={itemOnClick}>
						金句
					</Link>
				</li>
				{children}
			</ul>
		</nav>
	);
};

export default Navigator;
