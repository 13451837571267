import React from 'react';

import { storeActivePage } from 'models/common';
import { initializeBlog, initializeBlogLan } from 'models/blog';
import { getParameterByName, isEmpty, isExist } from 'util/helper';
import history from 'store/history';

export default {
	path: '/article-list/:id',
	components: () => [import(/* webpackChunkName: 'article-list' */ './component')],
	render: ([ArticleList]) => <ArticleList />,
	onEnter: async ({ store, params: { id } }) => {
		const lanIndex = window.location.href.indexOf('/article-list');
		const lanSlice = window.location.href.slice(0, lanIndex);
		const query = window.location.href.split('?')[1];
		const lan = lanSlice.substring(lanSlice.lastIndexOf('/') + 1);

		if (!id) {
			history.push('/');
		}

		if (isEmpty(getParameterByName('page'))) {
			const redirectUrl = isExist(query)
				? `/${lan}/article-list/${id}?page=1&${query}`
				: `/${lan}/article-list/${id}?page=1`;
			history.push(redirectUrl);
		}

		const urlQuery = getParameterByName('type');
		console.log('on Enter article-list');
		await store.dispatch(storeActivePage(`article-list/${urlQuery === 'region' ? 1 : id}`));
		if (lan !== 'tw') {
			await store.dispatch(initializeBlogLan(lan, parseInt(id, 10)));
		} else {
			await store.dispatch(initializeBlog(parseInt(id, 10), urlQuery));
		}
		console.log('on Enter article-list / end');
	},
};
