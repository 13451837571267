import React from 'react';
import classnames from 'classnames';
import ForwardIcon from 'images/icon/navigation-arrow-right.inline.svg';

import styles from './index.css';

const Button = ({
	// props
	className,
	type = '',
	label,
	icon = '',
	onClick = () => {},
	onMouseEnter = () => {},
	onMouseLeave = () => {},
	children,
}) => (
	<button
		className={classnames(styles.button, className, {
			[styles.normal]: type === 'normal',
			[styles.outline]: type === 'outline',
			[styles.outlinePrimaryColor]: type === 'outlinePrimaryColor',
		})}
		type="button"
		onClick={onClick}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
	>
		{children}
		{label && <span>{label}</span>}
		{icon === 'forward' && (
			<div className={styles.arrow}>
				<ForwardIcon />
			</div>
		)}
	</button>
);

export default Button;
