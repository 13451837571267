import { createAction, handleActions } from 'redux-actions';

import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

export const fetchSpecialDetail = createAction('FETCH_SPECIAL_DETAIL', id => async () => {
	const { status, message, data } = await wrapFetch(`plan/${id}`, {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

const reducer = {
	special: handleActions(
		{
			FETCH_SPECIAL_DETAIL_FULFILLED: (state, action) => ({
				...state,
				special: action.payload.plan,
				moreSpecial: action.payload.more_plans,
			}),
		},
		{
			special: {},
			moreSpecial: [],
		},
	),
};

const selectSpecial = state => ({
	...state.special,
});

export const useSpecial = () => useRedux(selectSpecial);

export default { reducer };
