import React from 'react';

import { initializeHome } from 'models/home';
import {
	fetchCategory,
	fetchSubcategory,
	fetchRegion,
	checkMemberToken,
	storeActivePage,
} from 'models/common';
import SearchRoute from './Search';
import ArticleListRoute from './ArticleList';
import SpecialRoute from './Special';
import ArticleRoute from './Article';
import QuoteRoute from './Quote';
import MagazineRoute from './Magazine';
import BookmarkRoute from './Bookmark';
import CopyrightRoute from './Copyright';

const routes = {
	path: '',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ store }) => {
		console.log('on Enter');
		window.scrollTo(0, 0);
		await store.dispatch(fetchCategory());
		await store.dispatch(fetchSubcategory());
		await store.dispatch(fetchRegion());
		await store.dispatch(checkMemberToken());
		console.log('on Enter / end');
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'home' */ './Home')],
			render: ([Home]) => <Home />,
			onEnter: async ({ store }) => {
				console.log('on Enter home');
				await store.dispatch(storeActivePage('home'));
				await store.dispatch(initializeHome());
				console.log('on Enter home / end');
			},
		},
		{
			path: '/tw',
			components: () => [],
			render: (_, children) => children,
			children: [
				{
					path: '',
					components: () => [import(/* webpackChunkName: 'home' */ './Home')],
					render: ([Home]) => <Home />,
					onEnter: async ({ store }) => {
						console.log('on Enter home');
						await store.dispatch(storeActivePage('home'));
						await store.dispatch(initializeHome());
						console.log('on Enter home / end');
					},
				},
				SearchRoute,
				ArticleListRoute,
				SpecialRoute,
				ArticleRoute,
				QuoteRoute,
				MagazineRoute,
				BookmarkRoute,
				CopyrightRoute,
			],
		},
		{
			path: '/:lang',
			components: () => [],
			render: (_, children) => children,
			children: [ArticleListRoute, ArticleRoute],
		},
		// children route no need i18n
	],
};

export default routes;
