import { createAction, handleActions } from 'redux-actions';

import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import storage from 'util/storage';

export const fetchBookmarkArticle = createAction(
	'FETCH_BOOKMARK_ARTICLE',
	(page = 1, per_page = 9) => async () => {
		const params = {
			method: 'GET',
			headers: {
				authorization: `Bearer ${storage.getItem('memberToken')}`,
			},
		};

		const { status, message, data } = await wrapFetch('post/keeps', params, { page, per_page });

		if (status !== 200) {
			throw new Error(message);
		}

		return data;
	},
);

const reducer = {
	bookmark: handleActions(
		{
			FETCH_BOOKMARK_ARTICLE_FULFILLED: (state, action) => ({
				...state,
				posts: action.payload.posts,
			}),
		},
		{
			posts: {},
		},
	),
};

const selectBookmark = state => ({
	...state.bookmark,
});

export const useBookmark = () => useRedux(selectBookmark, { fetchBookmarkArticle });

export default { reducer };
